import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import withLocation from '../hocs/location';
import RestaurantLayout from '../layouts/RestaurantLayout';

const RestaurantPageTemplate = ({
  data: { restaurant, regionRestaurants, region, city, siteConfig },
}) => (
  <RestaurantLayout
    restaurant={restaurant}
    regionRestaurants={regionRestaurants}
    region={region}
    siteConfig={siteConfig}
    city={city}
  />
);

RestaurantPageTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
RestaurantPageTemplate.defaultProps = {};

export default withLocation(RestaurantPageTemplate);

// variable taken from gatsby-node-js create page context
export const query = graphql`
  query getRestaurantById($slug: String!, $regionId: Int, $cityId: Int) {
    restaurant(slug: { eq: $slug }) {
      id
      foreignId
      title
      menuTitle
      metaTitleFormatted
      metaDescription
      metaTags
      ogImage
      slug
      gallery
      logo {
        url
      }
      content
      rating
      city
      street
      zip
      regionRanking
      cityRanking
      lat
      lng
      instagram
      phone
      website
      facebook
      facebookVideoURL
      featuredReviewsCount
      availabilitySessions
      mainsPriceRange
      priceInNZD
      acceptsAmex
      status
      onHoldText
      isNew
      featuredText
      breadcrumbs {
        edges {
          node {
            id
            title
            menuTitle
            slug
          }
        }
      }
      cuisines {
        edges {
          node {
            id: cuisineId
            label: cuisineTitle
          }
        }
      }
      allImages {
        edges {
          node {
            id
            url
          }
        }
      }
      allMenuImages {
        edges {
          node {
            id
            extension
            url
            isPDF
            originalWidth
            originalHeight
            menuImages {
              edges {
                node {
                  id
                  page
                  url
                  originalWidth
                  originalHeight
                }
              }
            }
          }
        }
      }
      reviewsCount
      ratingOutOfTen
      ratingSummaries {
        average
        count
        title
        total
      }
    }
    regionRestaurants: allRestaurant(
      filter: { regionId: { eq: $regionId }, status: { in: ["Live"] } }
    ) {
      edges {
        node {
          id
          foreignId
          cityId
          priceInNZD
          bookingPrice
          title
          status
          onHoldText
          slug
          availabilitySessions
          region {
            menuTitle
          }
          cuisines {
            edges {
              node {
                id: cuisineId
                label: cuisineTitle
              }
            }
          }
          allImages {
            edges {
              node {
                id
                url
              }
            }
          }
        }
      }
    }
    region: city(foreignId: { eq: $regionId }) {
      slug
      menuTitle
    }
    city(foreignId: { eq: $cityId }) {
      menuTitle
      foreignId
      slug
    }
    siteConfig {
      country
      language
      currencySymbol
      currency
      freeCreditAmount
    }
  }
`;
